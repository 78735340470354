import { graphql } from "gatsby";
import React from "react";
import { LayoutContainer } from "../containers/layout";
import PageHeadMeta from "../components/global/page-head-meta";
import { toPlainText } from "../lib/helpers";
import { PlaybookPage } from "../components/playbook-page";
import { suggest } from "../lib/suggestions";

export const query = graphql`
  query PlaybookTemplateQuery($id: String!) {
    doc: sanityPlaybook(id: { eq: $id }) {
      _type
      _id
      status
      title
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      mainImage {
        ...SanityImage
      }
      slug {
        current
      }
      teams
      skill
      integrations {
        ...Integration
      }
      suggestions {
        ... on SanityPost {
          ...cardNode
        }
        ... on SanityAsset {
          ...cardNodeAsset
        }
        ... on SanityPage {
          ...cardNodePage
        }
        ... on SanityPlaybook {
          ...cardNodePlaybook
        }
        ... on SanityDocumentation {
          ...cardNodeDocumentation
        }
      }
      relatedPosts {
        ...cardNode
      }
      relatedAssets {
        ...cardNodeAsset
      }
      relatedPlaybooks {
        ...cardNodePlaybook
      }
      _rawBody(resolveReferences: { maxDepth: 12 })
      authors {
        _key
        author {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
          name
          url
          title
          companies {
            title
          }
        }
      }
      categories {
        _id
      }
    }
    recentPosts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: [DESC] }
      filter: { slug: { current: { ne: null } }, isPublished: { eq: true } }
    ) {
      edges {
        node {
          ...cardNode
        }
      }
    }
  }
`;

const PlaybookTemplate = ({ data }) => (
  <LayoutContainer doc={data.doc}>
    <PageHeadMeta
      title={`${data.doc.title} Playbook`}
      description={toPlainText(data.doc._rawExcerpt)}
      image={data.doc.mainImage}
      doc={data.doc}
    />
    {data.doc && <PlaybookPage {...data.doc} suggest={suggest(data)} />}
  </LayoutContainer>
);

export default PlaybookTemplate;
